import {
  Box, Button, Grid2, useTheme,
} from '@mui/material';
import { LinkElement, TypographyElement, useContentElement } from '@plugins/next-cms-core';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Slider from '@components/atoms/Slider';
import BlogPreviewItem from '../molecules/BlogPreviewItem';
import Container from '../atoms/Container';

const PAGE_QUERY_PARAM = 'csp';

export default function CampaignBlock(props) {
  const { data } = props;
  const router = useRouter();
  const theme = useTheme();
  const {
    t,
    i18n,
  } = useTranslation();
  const [swiper, setSwiper] = useState();
  const { elementData } = useContentElement(
    data,
    CampaignBlock.dataSchema,
  );

  const {
    campaignDisplay: display,
    campaignBlockTags: tags,
    limit,
    moreLink,
  } = elementData;
  const tagIds = tags.data.map((tag) => tag.id);

  // Additional data
  const {
    error,
    data: additionalData,
    loading,
  } = useQuery(FETCH_ADDITIONAL_DATA, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      locale: i18n.language,
      tags: tagIds,
      limit: display === 'slider' ? 100 : limit || 4,
    },
    onCompleted: () => {
      const initialSlide = getInitialSlide();

      if (initialSlide > 0) {
        swiper.slideTo(initialSlide);
      }
    },
  });

  const slideIndex = parseInt(router.query[PAGE_QUERY_PARAM] ?? 0, 10);

  useEffect(() => {
    if (!swiper || !additionalData) return;
    swiper.slideTo(slideIndex);
  }, [slideIndex, swiper, additionalData]);

  if (error) {
    throw error;
  }

  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  if (!tags || tags.length === 0 || loading) {
    return null;
  }

  const items = additionalData.pages.data ?? [];

  return (
    <Box sx={{
      overflowX: 'hidden',
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    }}
    >
      <Container>
        {title && (
          <Box mb={5}>
            <TypographyElement data={title} />
          </Box>
        )}
        {display !== 'slider' && <List items={items} />}
        {display === 'slider' && (
          <Box sx={{ position: 'relative' }}>
            <Slider
              autoHeight
              items={items}
              pageQueryParam={PAGE_QUERY_PARAM}
              setSwiper={setSwiper}
              SlideComponent={BlogPreviewItem}
              sliderStyles={{
                paddingTop: 20,
                marginBottom: 20,
              }}
              swiper={swiper}
              swiperSlideStyles={{
                height: 'auto',
                display: 'flex',
              }}
            />
          </Box>
        )}
        {moreLink && (
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: theme.spacing(8),
          }}
          >
            <LinkElement data={moreLink}>
              <Button
                color="primary"
                component="a"
                variant="contained"
              >
                {t('components.contentTypes.CampaignBlock.showMore')}
              </Button>
            </LinkElement>
          </Box>
        )}
      </Container>
    </Box>
  );
}

function List(props) {
  const {
    items,
  } = props;

  return (
    <Grid2
      alignItems="stretch"
      container
      justifyContent="center"
      spacing={7}
    >
      {items.map((item) => (
        <Grid2
          key={item.id}
          size={{ md: 6, xs: 12 }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <BlogPreviewItem item={item} useMobileVariant />
        </Grid2>
      ))}
    </Grid2>
  );
}

function getInitialSlide() {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has(PAGE_QUERY_PARAM)) {
    return parseInt(queryParams.get(PAGE_QUERY_PARAM) ?? 0, 10);
  }
  return 0;
}

CampaignBlock.typeName = 'ComponentContentCampaignPreviews'; // Strapi element type
CampaignBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    campaignDisplay: PropTypes.oneOf([null, 'list', 'slider']),
    campaignBlockTags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })),
    limit: PropTypes.number.isRequired,
    moreLink: LinkElement.propTypes,
  }).isRequired,
};

CampaignBlock.graphQlSchema = `
... on ${CampaignBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  campaignDisplay: display
  campaignBlockTags: tags {
    data {
      id
    }
  }
  limit
  moreLink {
    ${LinkElement.graphQlSchema}
  }
}
`;

const FETCH_ADDITIONAL_DATA = gql`
  query GetBlogItems($locale: I18NLocaleCode!, $tags: [ID], $limit: Int!) {
    pages(
      filters: {
        pageType: { in: ["blogItem", "campaignItem"] },
        tags: { id: { in: $tags } }
      },
      sort: ["isPinnedToTop:desc", "publishDate:desc", "publishedAt:desc"],
      locale: $locale,
      pagination: { limit: $limit }
    ) {
      data {
        ${BlogPreviewItem.graphQlSchema}
      }
    }
  }
`;
