/* eslint-disable react/no-array-index-key */
import {
  Avatar, Box, Divider, Grid2, Skeleton, Typography, useTheme,
} from '@mui/material';
import { ColorScheme, useContentElement } from '@plugins/next-cms-core';
import { gql, useLazyQuery } from '@apollo/client';
import { memo, useEffect } from 'react';
import trim from 'lodash/trim';
import { FaStar } from 'react-icons/fa';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'next-i18next';
import { useInView } from 'react-intersection-observer';
import Button from '@components/atoms/Button';
import Container from '@components/atoms/Container';
import Link from '@components/atoms/Link';
import Hidden from '@components/atoms/Hidden';
import Image from '@components/atoms/Image2';

export default function CustomerTestimonialsBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const {
    t,
    i18n,
  } = useTranslation();
  const [ref, inView] = useInView();
  const { elementData } = useContentElement(
    data,
    CustomerTestimonialsBlock.dataSchema,
  );

  let {
    colorScheme,
  } = elementData;
  const tags = (elementData.tags || '')
    .split(',')
    .map((x) => trim(x))
    .filter((x) => !isEmpty(x));

  // Additional data
  const [fetchData, {
    data: additionalData,
    loading,
  }] = useLazyQuery(FETCH_ADDITIONAL_DATA, {
    variables: {
      locale: i18n.language,
      filters: {
        tag: { in: tags },
      },
      sort: ['index:desc', 'name'],
    },
  });

  const isLoading = loading;
  const items = additionalData?.customerTestimonials?.data ?? [];
  colorScheme = ColorScheme.getColorScheme(colorScheme, 'default');
  const cssColorClass = `${colorScheme[0].toUpperCase()}${colorScheme.substring(1)}`;

  const colorMapped = MUI_COLOR_MAPPING[colorScheme];
  const cssColorMappedClass = `${colorMapped[0].toUpperCase()}${colorMapped.substring(1)}`;

  useEffect(() => {
    if (inView) {
      fetchData();
    }
  }, [inView, fetchData]);

  return (
    <Box
      ref={ref}
      sx={{
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        backgroundColor: getRootBgColor(cssColorClass, theme),
      }}
    >
      <Container>
        <Typography
          align="center"
          color={colorMapped}
          component="h2"
          sx={{
            marginBottom: theme.spacing(5),
          }}
          variant="h3"
        >
          {t('components.contentTypes.CustomerTestimonialsBlock.customerTestimonials')}
        </Typography>
        {isLoading && (
          <>
            {Array.from(Array(3)
              .keys())
              .map((_, index) => (
                <Box key={index} alignItems="center" display="flex" mb={5}>
                  <Box flex={1}>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </Box>
                  <Box ml={3}>
                    <Skeleton height={100} variant="circle" width={100} />
                  </Box>
                </Box>
              ))}
          </>
        )}
        {!isLoading && items.length > 0 && (
          <Box>
            {items.map((item) => (
              <Box
                key={item.id}
                sx={{
                  '&:not(:last-child)': {
                    marginBottom: theme.spacing(3),
                  },
                  '&:last-child $divider': {
                    display: 'none',
                  },
                }}
              >
                <Box
                  alignItems="center"
                  display="flex"
                >
                  <Box display="flex" flex={1} flexDirection="column">
                    <Hidden dir="up" size="md">
                      <Box display="flex" justifyContent="center" mb={2}>
                        <Avatar
                          alt={`${item.attributes.name} Bewertung`}
                          imgProps={{
                            loading: 'lazy',
                          }}
                          src={item.attributes.image?.data?.attributes.url}
                          sx={{
                            width: 100,
                            height: 100,
                          }}
                        >
                          {item.attributes.name[0]}
                        </Avatar>
                      </Box>
                    </Hidden>
                    <Box alignItems="center" display="flex" mb={2}>
                      <Box
                        sx={{
                          position: 'relative',
                          width: 40,
                          height: 40,
                          marginRight: theme.spacing(1),
                        }}
                      >
                        <Box
                          component="span"
                          sx={{
                            position: 'absolute',
                            top: -7,
                            left: 0,
                            display: 'block',
                            marginRight: theme.spacing(1),
                            fontSize: 100,
                            lineHeight: '100px',
                            opacity: 0.25,
                            color: getQuoteIconColor(cssColorMappedClass, theme),
                          }}
                        >
                          “
                        </Box>
                      </Box>
                      <Typography
                        color={colorMapped}
                        component="h4"
                        variant="h6"
                      >
                        {item.attributes.url
                          ? (
                            <Link href={item.attributes.url} legacyBehavior>
                              <a rel="noopener nofollow" target="_blank">
                                {item.attributes.name}
                              </a>
                            </Link>
                          )
                          : item.attributes.name}
                      </Typography>
                      <Stars />
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        paddingLeft: `calc(40px + ${theme.spacing(1)})`,
                      }}
                    >
                      <Typography
                        color={colorMapped}
                        sx={{ fontStyle: 'italic' }}
                        variant="body2"
                      >
                        {item.attributes.text}
                      </Typography>
                      {item.attributes.url && (
                        <Box mt={3}>
                          <Link href={item.attributes.url} legacyBehavior>
                            <Button
                              color="primary"
                              component="a"
                              rel="noopener nofollow"
                              target="_blank"
                              variant="contained"
                            >
                              {t('components.contentTypes.CustomerTestimonialsBlock.more')}
                            </Button>
                          </Link>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Hidden dir="down" size="md">
                    <Box ml={3}>
                      <Avatar
                        alt={`${item.attributes.name} Bewertung`}
                        imgProps={{
                          loading: 'lazy',
                        }}
                        src={item.attributes.image?.data?.attributes.url}
                        sx={{
                          width: 100,
                          height: 100,
                        }}
                      >
                        {item.attributes.name[0]}
                      </Avatar>
                    </Box>
                  </Hidden>
                </Box>
                <Box display="flex" justifyContent="center" mt={5}>
                  <Divider
                    sx={{
                      backgroundColor: 'rgba(255,255,255,0.25)',
                      width: theme.spacing(10),
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        )}
        <Box sx={{ marginTop: 10 }}>
          <Grid2
            container
            spacing={3}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid2 size={{
              md: 'auto',
              xs: 12,
            }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Image
                  alt="Mobile Auszeichnung"
                  height={70}
                  isFluid
                  src="/images/seals/seal-mobile.svg"
                  style={{
                    display: 'block',
                    width: 260,
                    height: 'auto',
                  }}
                  width={355}
                />
              </Box>
            </Grid2>
            <Grid2 size={{
              md: 'auto',
              xs: 12,
            }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Image
                  alt="Autoscout24 Auszeichnung"
                  height={70}
                  isFluid
                  src="/images/seals/seal-autoscout24.svg"
                  style={{
                    display: 'block',
                    width: 260,
                    height: 'auto',
                  }}
                  width={355}
                />
              </Box>
            </Grid2>
            <Grid2 size={{
              md: 'auto',
              xs: 12,
            }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Image
                  alt="Mitsubishi Auszeichnung"
                  height={70}
                  isFluid
                  src="/images/seals/seal-mitsubishi.svg"
                  style={{
                    display: 'block',
                    width: 260,
                    height: 'auto',
                  }}
                  width={355}
                />
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </Container>
    </Box>
  );
}

// eslint-disable-next-line react/display-name
const Stars = memo(() => {
  const theme = useTheme();

  return (
    <Box display="flex" ml={2}>
      {Array.from(Array(5)
        .keys())
        .map((_, index) => (
          <FaStar
            key={index}
            style={{
              fontSize: 20,
              display: 'block',
              color: theme.palette.primary.main,
            }}
          />
        ))}
    </Box>
  );
});

function getRootBgColor(cssColorClass, theme) {
  switch (cssColorClass) {
    case 'Primary':
      return theme.palette.secondary.main;
    case 'Secondary':
      return theme.palette.primary.main;
    case 'Tertiary':
      return theme.palette.tertiary.main;
    case 'Quaternary':
      return theme.palette.quaternary.main;
    case 'Light':
      return theme.palette.light.main;
    case 'Dark':
      return theme.palette.dark.main;
    default:
      return 'inherit';
  }
}

function getQuoteIconColor(cssColorMappedClass, theme) {
  switch (cssColorMappedClass) {
    case 'Inherit':
      return theme.palette.secondary.contrastText;
    case 'Primary':
      return theme.palette.secondary.contrastText;
    case 'Secondary':
      return theme.palette.primary.contrastText;
    default:
      return 'inherit';
  }
}

CustomerTestimonialsBlock.typeName = 'ComponentContentCustomerTestimonials'; // Strapi element type
CustomerTestimonialsBlock.propTypes = {
  data: PropTypes.shape({
    tags: PropTypes.string,
    colorScheme: ColorScheme.propTypes,
  }).isRequired,
};

CustomerTestimonialsBlock.graphQlSchema = `
... on ${CustomerTestimonialsBlock.typeName} {
  id
  tags
  colorScheme {
    ${ColorScheme.graphQlSchema}
  }
}
`;

const FETCH_ADDITIONAL_DATA = gql`
  query GetItems($filters: CustomerTestimonialFiltersInput, $sort: [String], $locale: I18NLocaleCode!) {
    customerTestimonials(filters: $filters, sort: $sort, locale: $locale) {
      data {
        id
        attributes {
          name
          text
          tag
          url
          index
          image {
            data {
              attributes {
                name
                width
                height
                formats
                url
              }
            }
          }
        }
      }
    }
  }
`;

const MUI_COLOR_MAPPING = {
  default: 'inherit',
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'inherit',
  quaternary: 'inherit',
  light: 'inherit',
  dark: 'secondary',
};
