import {
  Box, Button, Grid2, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import {
  LinkElement, MediaElement, TypographyElement, useContentElement,
} from '@plugins/next-cms-core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import PageInsetTop from '@components/atoms/PageInsetTop';
import { useTranslation } from 'next-i18next';
import Slider from '@components/atoms/Slider';
import Container from '@components/atoms/Container';
import Image from '@components/atoms/Image2';
import Link from '@components/atoms/Link';

const HEADER_MAX_HEIGHT = 800;

function PageHeaderBlock(props) {
  const {
    data,
    context: { page },
  } = props;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const [useSlider, setUseSlider] = useState(false);
  const [swiper, setSwiper] = useState();
  const [canGoPrevious, setCanGoPrevious] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);
  const isTopContentOffset = page.isTopContentOffset || page.attributes?.isTopContentOffset;
  const [slideIndex, setSlideIndex] = useState(0);
  const { elementData } = useContentElement(
    data,
    PageHeaderBlock.dataSchema,
  );

  const {
    pageHeaderSlides,
    enablePromotionDisplay,
    isContained,
    visibility,
  } = elementData;
  let { height } = elementData;
  const maxWidth = '1500px';

  if (!height || height <= 0) {
    height = HEADER_MAX_HEIGHT;
  }

  const handleGoToPreviousPage = () => {
    swiper?.slidePrev();
  };
  const handleGoToNextPage = () => {
    swiper?.slideNext();
  };

  const handleSlideChange = () => {
    setSlideIndex(swiper.activeIndex);
    setCanGoPrevious(swiper.activeIndex > 0);
    setCanGoNext(swiper.activeIndex < swiper.snapGrid.length - 1);
  };

  const display = { xs: 'flex' };
  const displayPageOffset = { xs: 'none' };

  switch (visibility) {
    case 'desktop':
      display.xs = 'none';
      display.md = 'flex';
      displayPageOffset.xs = page.isTopContentOffset ? 'none' : 'block';
      displayPageOffset.md = 'none';
      break;
    case 'mobile':
      display.xs = 'flex';
      display.md = 'none';
      displayPageOffset.xs = 'none';
      displayPageOffset.md = page.isTopContentOffset ? 'none' : 'block';
      break;
    default:
      break;
  }

  useEffect(() => {
    const tm = setTimeout(() => {
      setUseSlider(true);
    }, 2000);

    return () => {
      clearTimeout(tm);
    };
  }, [setUseSlider]);

  return (
    <>
      <PageInsetTop
        sx={{ display: displayPageOffset }}
      />
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '0 auto',
          display,
          minHeight: {
            xs: height / 2,
            md: height,
          },
          maxWidth: isContained ? maxWidth : undefined,
        }}
      >
        {pageHeaderSlides.length > 1 && canGoPrevious && (
          <Button
            aria-label="previous"
            disabled={!canGoPrevious}
            onClick={handleGoToPreviousPage}
            size="small"
            sx={{
              fontSize: theme.typography.pxToRem(45),
              position: 'absolute',
              display: {
                xs: 'none',
                md: 'block',
              },
              minWidth: 0,
              top: '50%',
              zIndex: 2,
              color: 'white',
              backgroundColor: 'transparent',
              '&.Mui-disabled': {
                color: 'white',
              },
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
              '&:hover': {
                backgroundColor: 'transparent',
              },
              left: theme.spacing(10),
              '& svg': {
                filter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4))',
              },
              '& svg:nth-of-type(2)': {
                marginLeft: theme.spacing(-4.5),
                display: 'none',
              },
              '&:hover svg:nth-of-type(2)': {
                display: 'inline',
              },
            }}
          >
            <FiChevronLeft />
            <FiChevronLeft />
          </Button>
        )}
        {!useSlider ? (
          <Box
            sx={{
              width: '100%',
              minHeight: {
                xs: height / 2,
                md: height,
              },
            }}
          >
            {pageHeaderSlides.length > 0 && (
              <Slide
                enablePriority
                height={height}
                isMobile={isSmall}
                item={pageHeaderSlides[0]}
                items={pageHeaderSlides}
                theme={theme}
              />
            )}
          </Box>
        ) : (
          <Slider
            allowTouchMove={pageHeaderSlides.length > 1}
            autoplay={pageHeaderSlides.length > 1 && { delay: 10000 }}
            handleSlideChange={handleSlideChange}
            hidePagination
            isButtonsVisible={false}
            items={pageHeaderSlides}
            setSwiper={setSwiper}
            SlideComponent={Slide}
            slideComponentProps={{
              height,
              items: pageHeaderSlides,
              theme,
              isMobile: isSmall,
            }}
            sliderStyles={{
              width: '100%',
              minHeight: {
                xs: height / 2,
                md: height,
              },
            }}
            swiper={swiper}
            swiperSlideStyles={{
              height: 'auto',
              display: 'flex',
            }}
          />
        )}
        {pageHeaderSlides.length > 1 && canGoNext && (
          <Button
            aria-label="next"
            disabled={!canGoNext}
            onClick={handleGoToNextPage}
            size="small"
            sx={{
              fontSize: theme.typography.pxToRem(45),
              position: 'absolute',
              display: {
                xs: 'none',
                md: 'block',
              },
              minWidth: 0,
              top: '50%',
              zIndex: 2,
              color: 'white',
              backgroundColor: 'transparent',
              '&.Mui-disabled': {
                color: 'white',
              },
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
              '&:hover': {
                backgroundColor: 'transparent',
              },
              right: theme.spacing(10),
              '& svg': {
                filter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4))',
              },
              '& svg:first-child': {
                marginRight: theme.spacing(-4.5),
                display: 'none',
              },
              '&:hover svg:first-child': {
                display: 'inline',
              },
            }}
          >
            <FiChevronRight />
            <FiChevronRight />
          </Button>
        )}
        {enablePromotionDisplay && (
          <>
            <Box
              sx={{
                display: {
                  xs: 'none',
                  md: 'block',
                },
                pointerEvents: 'none',
                position: 'absolute',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
                height: '100%',
                maxWidth: CONTENT_MAX_WIDTH,
                zIndex: 2,
                '& a': {
                  pointerEvents: 'all',
                },
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: isTopContentOffset ? 45 : 160,
                  left: theme.spacing(2),
                  backgroundColor: 'rgba(0,0,0,0.45)',
                  paddingTop: theme.spacing(1),
                  paddingBottom: theme.spacing(1),
                  paddingLeft: theme.spacing(2),
                  paddingRight: theme.spacing(2),
                  borderRadius: `${theme.shape.borderRadius}px`,
                }}
              >
                <Box
                  alignItems="center"
                  display="flex"
                >
                  <Box
                    mr={2}
                    sx={{
                      '& img': {
                        width: 50,
                        height: 'auto',
                        [theme.breakpoints.up('md')]: {
                          width: 70,
                        },
                      },
                    }}
                  >
                    <Link href={`${t('components.contentTypes.PageHeaderBlock.urls.partner')}#scroll-fuechse`}>
                      <a>
                        <Image
                          alt="Füchse Berlin Logo"
                          height={70}
                          src="/images/promotion/fuechse-logo_small.png"
                          width={70}
                        />
                      </a>
                    </Link>
                  </Box>
                  <Box>
                    <Typography
                      color="secondary"
                      dangerouslySetInnerHTML={{ __html: t('components.contentTypes.PageHeaderBlock.officialAutomotivePartner', { break: '<br />' }) }}
                      variant="caption"
                    />
                  </Box>
                </Box>
                <Box
                  mt={1}
                  sx={{
                    '& img': {
                      width: 50,
                      height: 'auto',
                      [theme.breakpoints.up('md')]: {
                        width: 70,
                      },
                    },
                  }}
                >
                  <Box mb={1} textAlign="center">
                    <Typography color="secondary" sx={{ fontWeight: 'bold' }} variant="caption">
                      Ausgezeichnet von
                    </Typography>
                  </Box>
                  <Grid2 alignItems="center" container spacing={2}>
                    <Grid2 size={{ xs: 4 }}>
                      <Link href={`${t('components.contentTypes.PageHeaderBlock.urls.partner')}#scroll-mobilede`}>
                        <a>
                          <Image
                            alt="Mobile.De Logo"
                            height={39}
                            src="/images/promotion/mobile-de-logo.svg"
                            width={164}
                          />
                        </a>
                      </Link>
                    </Grid2>
                    <Grid2 size={{ xs: 4 }}>
                      <Link href={`${t('components.contentTypes.PageHeaderBlock.urls.partner')}#scroll-ihk`}>
                        <a>
                          <Image
                            alt="IHK Logo"
                            height={280}
                            src="/images/promotion/ihk-logo.svg"
                            width={561}
                          />
                        </a>
                      </Link>
                    </Grid2>
                    <Grid2 size={{ xs: 4 }}>
                      <Link href={`${t('components.contentTypes.PageHeaderBlock.urls.partner')}#scroll-automotivebusinessaward`}>
                        <a>
                          <Image
                            alt="KFZ Betrieb Logo"
                            height={68}
                            src="/images/promotion/kfz-gewerbe-logo.svg"
                            width={274}
                          />
                        </a>
                      </Link>
                    </Grid2>
                  </Grid2>
                </Box>
              </Box>
            </Box>
            {pageHeaderSlides[slideIndex]?.disclaimer && (
              <Box
                sx={{
                  display: {
                    xs: 'block',
                    sm: 'none',
                  },
                  backdropFilter: 'saturate(180%) blur(3px)',
                  paddingTop: theme.spacing(1),
                  paddingBottom: theme.spacing(1),
                  [theme.breakpoints.up('md')]: {
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    backgroundColor: 'rgba(0,0,0,0.35)',
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                    borderTopLeftRadius: 2,
                    borderTopRightRadius: 2,
                  },
                }}
              >
                <Container>
                  <TypographyElement
                    data={getDisclaimer(pageHeaderSlides[slideIndex]?.disclaimer)}
                  />
                </Container>
              </Box>
            )}
            <Box
              alignSelf="stretch"
              my={1}
              sx={{
                display: {
                  xs: 'block',
                  md: 'none',
                },
              }}
            >
              <Container>
                <Box alignItems="center" display="flex">
                  <Box
                    mr={2}
                    sx={{
                      '& img': {
                        width: 50,
                        height: 'auto',
                        [theme.breakpoints.up('md')]: {
                          width: 70,
                        },
                      },
                    }}
                  >
                    <Link href={`${t('components.contentTypes.PageHeaderBlock.urls.partner')}#scroll-fuechse`}>
                      <a>
                        <Image
                          alt="Füchse Berlin Logo"
                          height={50}
                          src="/images/promotion/fuechse-logo_small.png"
                          width={50}
                        />
                      </a>
                    </Link>
                  </Box>
                  <Box>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'components.contentTypes.PageHeaderBlock.officialAutomotivePartner',
                          { break: '' },
                        ),
                      }}
                      variant="caption"
                    />
                  </Box>
                </Box>
                <Box mt={1} p={1}>
                  <Box mb={1}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="caption">
                      Ausgezeichnet von
                    </Typography>
                  </Box>
                  <Grid2 alignItems="center" container spacing={5}>
                    <Grid2 size={{ xs: 4 }}>
                      <Link href={`${t('components.contentTypes.PageHeaderBlock.urls.partner')}#scroll-mobilede`}>
                        <a>
                          <Image
                            alt="Mobile.De Logo"
                            height={39}
                            src="/images/promotion/mobile-de-logo-dark.svg"
                            style={{
                              width: '100%',
                              height: 'auto',
                            }}
                            width={164}
                          />
                        </a>
                      </Link>
                    </Grid2>
                    <Grid2 size={{ xs: 4 }}>
                      <Link href={`${t('components.contentTypes.PageHeaderBlock.urls.partner')}#scroll-ihk`}>
                        <a>
                          <Image
                            alt="IHK Logo"
                            height={68}
                            src="/images/promotion/ihk-logo-dark.svg"
                            style={{
                              width: '100%',
                              height: 'auto',
                            }}
                            width={274}
                          />
                        </a>
                      </Link>
                    </Grid2>
                    <Grid2 size={{ xs: 4 }}>
                      <Link href={`${t('components.contentTypes.PageHeaderBlock.urls.partner')}#scroll-automotivebusinessaward`}>
                        <a>
                          <Image
                            alt="KFZ Betrieb Logo"
                            height={68}
                            src="/images/promotion/kfz-gewerbe-logo-dark.svg"
                            style={{
                              width: '100%',
                              height: 'auto',
                            }}
                            width={274}
                          />
                        </a>
                      </Link>
                    </Grid2>
                  </Grid2>
                </Box>
              </Container>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default PageHeaderBlock;

function Slide(props) {
  const {
    enablePriority,
    isMobile,
    height,
    item,
    items,
    theme,
  } = props;
  const {
    titlePosition,
    titleOffsetX,
    titleOffsetY,
    titleBackground,
    link,
    mobileMedia,
  } = item;
  let { media } = item;
  const index = items.findIndex((i) => i.id === item.id);
  let title = null;
  let subtitle = null;
  let disclaimer = null;

  if (isMobile && mobileMedia) {
    media = mobileMedia;
  }

  if (item.title) {
    title = omitBy(item.title, isNull);
    defaults(title, {
      semanticVariant: 'h1',
      displayVariant: 'h1',
    });
  }

  if (item.subtitle) {
    subtitle = omitBy(item.subtitle, isNull);
    defaults(subtitle, {
      semanticVariant: 'h5',
      displayVariant: 'h5',
    });
  }

  if (item.disclaimer) {
    disclaimer = getDisclaimer(item.disclaimer);
  }

  const titleContainerStyle = {
    transform: `translate(${titleOffsetX ?? 0}%, ${titleOffsetY ?? 0}%)`,
  };

  return (
    <Box
      sx={{
        position: 'relative',
        flex: 1,
      }}
    >
      <Box
        sx={{
          zIndex: -1,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <MediaElement
          alt="slider image"
          data={media}
          format={isMobile ? 'medium' : 'original'}
          isAbsoluteFill
          priority={enablePriority && index === 0}
          style={{
            height: '100%',
          }}
        />
      </Box>
      <Container
        sx={{
          zIndex: 1,
          pointerEvents: 'none',
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: heightStyleMobile,
          [theme.breakpoints.up('lg')]: {
            height: heightStyleDesktop,
          },
          // paddingTop: HEADER_HEIGHT + theme.spacing(CONTENT_CONTAINER_PADDING_FACTOR),
          paddingTop: '180px',
          '& * > p': {
            margin: 0,
          },
          maxHeight: {
            xs: height / 2,
            md: height,
          },
          ...(getContentPosition(titlePosition)),
        }}
      >
        {(title || subtitle) && (
          <Box
            sx={{
              ...titleContainerStyle,
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              borderRadius: `${theme.shape.borderRadius}px`,
              backgroundColor: getBackgroundColor(titleBackground, theme),
            }}
          >
            {subtitle && (
              <TypographyElement
                color="secondary"
                data={subtitle}
              />
            )}
            {title && (
              <TypographyElement
                color="secondary"
                data={title}
              />
            )}
          </Box>
        )}
        {disclaimer && (
          <Box
            sx={{
              display: {
                xs: 'none',
                md: 'block',
              },
              backdropFilter: 'saturate(180%) blur(3px)',
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              backgroundColor: 'rgba(0,0,0,0.35)',
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              borderTopLeftRadius: 2,
              borderTopRightRadius: 2,
            }}
          >
            <TypographyElement
              color="secondary"
              data={disclaimer}
            />
          </Box>
        )}
      </Container>
      {link && (
        <LinkElement data={link}>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <Box
            aria-label="slider link"
            component="a"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'transparent',
            }}
          />
        </LinkElement>
      )}
    </Box>
  );
}

const CONTENT_MAX_WIDTH = 2000;
const heightStyleMobile = 'calc(80vh - 45px)';
const heightStyleDesktop = 'calc(100vh - 45px)';

function getContentPosition(titlePosition) {
  switch (titlePosition) {
    case 'TopLeft':
      return {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      };
    case 'TopCenter':
      return {
        justifyContent: 'flex-start',
        alignItems: 'center',
      };
    case 'TopRight':
      return {
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
      };
    case 'CenterLeft':
      return {
        justifyContent: 'center',
        alignItems: 'flex-start',
      };
    case 'CenterCenter':
      return {
        justifyContent: 'center',
        alignItems: 'center',
      };
    case 'CenterRight':
      return {
        justifyContent: 'center',
        alignItems: 'flex-end',
      };
    case 'BottomLeft':
      return {
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
      };
    case 'BottomCenter':
      return {
        justifyContent: 'flex-end',
        alignItems: 'center',
      };
    case 'BottomRight':
      return {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      };
    default:
      return {};
  }
}

function getBackgroundColor(titleBackground, theme) {
  switch (titleBackground) {
    case 'primary':
      return theme.palette.primary.light;
    case 'light':
      return 'rgba(255,255,255,0.45)';
    case 'dark':
      return 'rgba(0,0,0,0.45)';
    default:
      return 'transparent';
  }
}

PageHeaderBlock.typeName = 'ComponentContentPageHeader'; // Strapi element type
PageHeaderBlock.propTypes = {
  data: PropTypes.shape({
    height: PropTypes.number,
    enableAutoplay: PropTypes.bool.isRequired,
    enablePromotionDisplay: PropTypes.bool.isRequired,
    pageHeaderSlides: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      titlePosition: PropTypes.string.isRequired,
      titleBackground: PropTypes.oneOf([
        null, 'default', 'primary', 'light', 'dark',
      ]),
      link: LinkElement.propTypes,
      media: MediaElement.propTypes,
      mobileMedia: MediaElement.propTypes,
      title: TypographyElement.propTypes,
      subtitle: TypographyElement.propTypes,
      disclaimer: TypographyElement.propTypes,
    })),
  }).isRequired,
};

PageHeaderBlock.graphQlSchema = `
... on ${PageHeaderBlock.typeName} {
  id
  height
  enableAutoplay
  enablePromotionDisplay
  isContained
  visibility
  pageHeaderSlides: slides(pagination: { limit: 100 }) {
    id
    titlePosition
    titleOffsetX
    titleOffsetY
    titleBackground
    link {
      ${LinkElement.graphQlSchema}
    }
    media {
      ${MediaElement.graphQlSchema}
    }
    mobileMedia {
      ${MediaElement.graphQlSchema}
    }
    title {
      ${TypographyElement.graphQlSchema}
    }
    subtitle {
      ${TypographyElement.graphQlSchema}
    }
    disclaimer {
      ${TypographyElement.graphQlSchema}
    }
  }
}
`;

function getDisclaimer(disclaimer) {
  disclaimer = omitBy(disclaimer, isNull);
  defaults(disclaimer, {
    semanticVariant: 'div',
    displayVariant: 'caption',
  });
  return disclaimer;
}
