import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid2,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  FiChevronDown, FiClock, FiExternalLink, FiInfo, FiMapPin, FiPhone,
} from 'react-icons/fi';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import useConsent from '@lib/hooks/useConsent';
import { useContentElement } from '@plugins/next-cms-core';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'next-i18next';
import Hidden from '@components/atoms/Hidden';
import Link from '@components/atoms/Link';
import Container from '@components/atoms/Container';
import ProvenExpertGoogleStars from '@components/organisms/ProvenExpertGoogleStars';
import ConsentRequiredFallback from '@components/molecules/ConsentRequiredFallback';

const { publicRuntimeConfig } = getConfig();

function MapBlockComponent(props) {
  const { appData, data } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const hasConsent = useConsent('googleMaps');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [ref, inView] = useInView();
  const { elementData } = useContentElement(
    data,
    MapBlock.dataSchema,
  );
  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [center, setCenter] = useState(
    isMobile
      ? DEFAULT_CENTER.mobile
      : DEFAULT_CENTER.desktop,
  );
  // eslint-disable-next-line no-unused-vars
  const [selectedBranch, setSelectedBranch] = useState(null);

  const {
    isBranchOverlayVisible,
    isMapHeaderSummaryVisible,
  } = elementData;
  const branches = appData.branches.data;

  const onLoad = useCallback((newMap) => {
    // const bounds = new window.google.maps.LatLngBounds();
    // newMap.fitBounds(bounds);
    setMap(newMap);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleBranchSelected = (id) => {
    let offsetLat = 0;
    let offsetLng = 0;

    if (!isMobile) {
      offsetLat = 0;
      offsetLng = -0.05;
    }

    const branch = branches.find((item) => item.id === id);
    setZoom(DEFAULT_ZOOM_BRANCH);
    setCenter({
      lat: branch.attributes.geocodeLat + offsetLat,
      lng: branch.attributes.geocodeLng + offsetLng,
    });
    setSelectedBranch(id);
  };
  const handleBranchDeselected = () => {
    setZoom(DEFAULT_ZOOM);
    setCenter(
      isMobile
        ? DEFAULT_CENTER.mobile
        : DEFAULT_CENTER.desktop,
    );
    setSelectedBranch(null);
  };
  const handleClickBranch = (branch) => () => {
    openMap(`${branch.attributes.addressStreet} ${branch.attributes.addressZipcode}, ${branch.attributes.addressCity}, ${branch.attributes.addressCountry}`);
  };

  return (
    <Box ref={ref} sx={{ contentVisibility: 'auto' }}>
      {isMapHeaderSummaryVisible && (
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            paddingTop: theme.spacing(10),
            paddingBottom: theme.spacing(10),
          }}
        >
          <Container>
            <Box mb={3}>
              <Typography
                align="center"
                color="secondary"
                component="h3"
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  '& span': {
                    paddingRight: 2,
                  },
                }}
                variant="h3"
              >
                <span>
                  {t('components.contentTypes.MapBlock.your')}
                </span>
                <span className="hyundai_tag">Hyundai</span>
                <span className="mgmotor_tag">MG Motor</span>
                <span className="mitsubishi_tag">Mitsubishi</span>
                <span className="fuso_tag">FUSO</span>
                <span className="maxus_tag">MAXUS</span>
                {t('components.contentTypes.MapBlock.carDealership')}
              </Typography>
            </Box>
            <Grid2 container>
              <Grid2 size={{
                md: 3,
                sm: 6,
                xs: 12,
              }}
              >
                <Link href={t('components.contentTypes.MapBlock.urls.branches')}>
                  <Typography align="center" color="secondary" component="a" variant="body1">
                    <Typography
                      component="strong"
                      sx={{
                        fontWeight: theme.typography.fontWeightBold,
                      }}
                    >
                      {branches.length}
                      {' '}
                      {t('components.contentTypes.MapBlock.branches')}
                    </Typography>
                    {' '}
                    {t('components.contentTypes.MapBlock.berlin')}
                  </Typography>
                </Link>
              </Grid2>
              <Grid2 size={{
                md: 3,
                sm: 6,
                xs: 12,
              }}
              >
                <Typography align="center" color="secondary" variant="body1">
                  <Typography
                    component="strong"
                    sx={{
                      fontWeight: theme.typography.fontWeightBold,
                    }}
                  >
                    {t('components.contentTypes.MapBlock.half')}
                  </Typography>
                  {' '}
                  {t('components.contentTypes.MapBlock.cars')}
                </Typography>
              </Grid2>
              <Grid2 size={{
                md: 3,
                sm: 6,
                xs: 12,
              }}
              >
                <Typography align="center" color="secondary" variant="body1">
                  <Typography
                    component="strong"
                    sx={{
                      fontWeight: theme.typography.fontWeightBold,
                    }}
                  >
                    {t('components.contentTypes.MapBlock.everything')}
                  </Typography>
                  {' '}
                  {t('components.contentTypes.MapBlock.underOneRoof')}
                </Typography>
              </Grid2>
              <Grid2 size={{
                md: 3,
                sm: 6,
                xs: 12,
              }}
              >
                <Typography align="center" color="secondary" variant="body1">
                  <Typography
                    component="strong"
                    sx={{
                      fontWeight: theme.typography.fontWeightBold,
                    }}
                  >
                    30
                    {' '}
                    {t('components.contentTypes.MapBlock.years')}
                  </Typography>
                  {' '}
                  {t('components.contentTypes.MapBlock.experience')}
                </Typography>
              </Grid2>
            </Grid2>
            {isBranchOverlayVisible && (
              <Hidden dir="up" size="md">
                <Box mt={5}>
                  <Branches
                    branches={branches}
                    onBranchDeselected={handleBranchDeselected}
                    onBranchSelected={handleBranchSelected}
                    t={t}
                  />
                </Box>
              </Hidden>
            )}
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              mt={5}
            >
              <Paper>
                <Box px={5} py={1}>
                  <ProvenExpertGoogleStars />
                </Box>
              </Paper>
            </Box>
          </Container>
        </Box>
      )}
      {hasConsent && (
        <Box
          sx={{
            height: '600px',
            position: 'relative',
            backgroundColor: theme.palette.tertiary.main,
          }}
        >
          {(!map ? inView : true) && (
            <MapContainer
              branchData={branches}
              center={center}
              onBranchClick={handleClickBranch}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={MAP_OPTIONS}
              zoom={zoom}
            />
          )}
          {isBranchOverlayVisible && inView && (
            <Hidden dir="down" size="md">
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  pointerEvents: 'none',
                }}
              >
                <Container>
                  <Box my={5}>
                    <Grid2 container>
                      <Grid2 size={{ xs: 6 }}>
                        <Branches
                          branches={branches}
                          onBranchDeselected={handleBranchDeselected}
                          onBranchSelected={handleBranchSelected}
                          t={t}
                        />
                      </Grid2>
                    </Grid2>
                  </Box>
                </Container>
              </Box>
            </Hidden>
          )}
        </Box>
      )}
      {!hasConsent && (
        <ConsentRequiredFallback />
      )}
      <style global jsx>
        {`

          .mapContainer {
            width: 100%;
            height: 600px;
          }

          .markerLabel {
            backdrop-filter: saturate(180%) blur(20px);
            background-color: rgba(255, 255, 255, 0.75);
            border-radius: 100px;
            border-style: solid;
            border-width: 2px;
            border-color: ${theme.palette.primary.main};
            padding: 2px 7px 2px 8px;
          }

        `}
      </style>
    </Box>
  );
}

const MapBlock = memo(MapBlockComponent);
export default MapBlock;

function MapContainer(props) {
  const {
    onLoad,
    onUnmount,
    onBranchClick,
    branchData,
    center,
    zoom,
  } = props;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: publicRuntimeConfig.GOOGLE_API_KEY,
  });

  return isLoaded ? (
    <GoogleMap
      center={center}
      mapContainerClassName="mapContainer"
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={MAP_OPTIONS}
      zoom={zoom}
    >
      {branchData.map((item) => (
        <Marker
          key={item.id}
          label={{
            text: item.attributes.name,
            className: 'markerLabel',
            fontFamily: 'Mulish, sans-serif',
            fontSize: '12px',
            fontWeight: '700',
          }}
          onClick={onBranchClick(item)}
          position={{
            lat: item.attributes.geocodeLat,
            lng: item.attributes.geocodeLng,
          }}
        />
      ))}
    </GoogleMap>
  ) : null;
}

function Branches(props) {
  const {
    t,
    branches,
    onBranchSelected,
    onBranchDeselected,
  } = props;
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (expanded && onBranchSelected) {
      onBranchSelected(expanded);
    } else if (!expanded && onBranchDeselected) {
      onBranchDeselected();
    }
  }, [expanded]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return branches.map((branch) => (
    <Accordion
      key={branch.id}
      expanded={expanded === branch.id}
      onChange={handleChange(branch.id)}
      sx={{
        pointerEvents: 'all',
      }}
    >
      <AccordionSummary
        expandIcon={<FiChevronDown />}
      >
        <Typography variant="body2">
          {t('components.contentTypes.MapBlock.carDealership')}
          {' '}
          <strong>{branch.attributes.name}</strong>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          flexDirection: 'column',
        }}
      >
        {branch.attributes.addressStreet && (
          <Box>
            <Grid2
              alignItems="center"
              container
              spacing={3}
            >
              <Grid2
                size={{
                  sm: 'auto',
                  xs: 12,
                }}
                sx={{ flex: '1 !important' }}
              >
                <Box alignItems="center" display="flex">
                  <Box mr={1}>
                    <FiMapPin
                      style={{
                        width: 20,
                        height: 20,
                        display: 'block',
                      }}
                    />
                  </Box>
                  <Typography component="div" variant="body2">
                    <strong>{branch.attributes.addressStreet}</strong>
                    {', '}
                    {branch.attributes.addressZipcode}
                    {' '}
                    {branch.attributes.addressCity}
                  </Typography>
                </Box>
              </Grid2>
              {branch.attributes.pageUrl && (
                <Grid2 size={{
                  sm: 'auto',
                  xs: 12,
                }}
                >
                  <Link href={branch.attributes.pageUrl} legacyBehavior>
                    <Button
                      color="primary"
                      component="a"
                      fullWidth
                      startIcon={<FiInfo />}
                      variant="outlined"
                    >
                      {t('components.contentTypes.MapBlock.location')}
                    </Button>
                  </Link>
                </Grid2>
              )}
              <Grid2 size={{
                sm: 'auto',
                xs: 12,
              }}
              >
                <Button
                  color="primary"
                  fullWidth
                  onClick={openRoute(
                    `${branch.attributes.addressStreet}, ${branch.attributes.addressZipcode} ${branch.attributes.addressCity}, ${branch.attributes.addressCountry}`,
                  )}
                  startIcon={<FiExternalLink />}
                  variant="outlined"
                >
                  {t('components.contentTypes.MapBlock.approach')}
                </Button>
              </Grid2>
            </Grid2>
          </Box>
        )}
        <Box my={3}>
          <Divider />
        </Box>
        <Grid2 alignItems="center" container spacing={3}>
          <Grid2 size={{
            sm: 6,
            xs: 12,
          }}
          >
            <Typography variant="h6">
              {t('components.contentTypes.MapBlock.sale')}
            </Typography>
            <BranchDepartment
              businessHours={branch.attributes.businessHoursSales}
              phone={branch.attributes.salesPhone}
              t={t}
            />
          </Grid2>
          <Grid2 size={{
            sm: 6,
            xs: 12,
          }}
          >
            <Typography variant="h6">
              {t('components.contentTypes.MapBlock.workshop')}
            </Typography>
            <BranchDepartment
              businessHours={branch.attributes.businessHoursWorkshop}
              phone={branch.attributes.workshopPhone}
              t={t}
            />
          </Grid2>
        </Grid2>
      </AccordionDetails>
    </Accordion>
  ));
}

Branches.propTypes = {
  branches: PropTypes.array.isRequired,
  // Events
  onBranchSelected: PropTypes.func,
  onBranchDeselected: PropTypes.func,
};

function BranchDepartment({
  t,
  businessHours,
  phone,
}) {
  const trimTime = (time) => time.substring(0, 5);

  return (
    <div>
      {businessHours.length > 0 && (
        <Box mt={1}>
          <Box display="flex">
            <Box mr={2} mt={0.5}>
              <FiClock className="icon" />
            </Box>
            <Box flex={1}>
              {businessHours.map((item) => (
                <Grid2 key={item.id} container>
                  <Grid2 size={{ xs: 4 }}>
                    {`${DAY_MAPPING[item.dayRangeStart]}.`}
                    {item.dayRangeEnd && ` - ${DAY_MAPPING[item.dayRangeEnd]}.`}
                  </Grid2>
                  <Grid2 size={{ xs: 8 }}>
                    {trimTime(item.dayTimeStart)}
                    {item.dayTimeEnd && ` - ${trimTime(item.dayTimeEnd)}`}
                    {' '}
                    {t('components.contentTypes.MapBlock.clock')}
                  </Grid2>
                </Grid2>
              ))}
            </Box>
          </Box>
        </Box>
      )}
      {phone && (
        <Box mt={3}>
          <Link href={`tel:${phone}`} legacyBehavior>
            <Button
              color="primary"
              component="a"
              fullWidth
              size="small"
              startIcon={<FiPhone />}
              variant="outlined"
            >
              {phone}
            </Button>
          </Link>
        </Box>
      )}
    </div>
  );
}

MapBlock.typeName = 'ComponentContentMap'; // Strapi element type
MapBlockComponent.propTypes = {
  data: PropTypes.shape({
    is_branch_overlay_visible: PropTypes.bool,
    is_map_header_summary_visible: PropTypes.bool,
  }).isRequired,
};

MapBlock.graphQlSchema = `
... on ${MapBlock.typeName} {
  id
  isBranchOverlayVisible
  isMapHeaderSummaryVisible
}
`;

function openMap(address) {
  const url = `http://maps.apple.com/?saddr=${encodeURIComponent(address)}`;
  const win = window.open(url, '_blank');
  win.focus();
}

function openRoute(destination) {
  return () => {
    const isAppleUser = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);
    let url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(destination)}`;

    if (isAppleUser) {
      url = `http://maps.apple.com/?q=${encodeURIComponent(destination)}`;
    }

    const win = window.open(url, '_blank');
    win.focus();
  };
}

const DAY_MAPPING = {
  monday: 'Mo',
  tuesday: 'Di',
  wednesday: 'Mi',
  thursday: 'Do',
  friday: 'Fr',
  saturday: 'Sa',
  sunday: 'So',
};
const DEFAULT_ZOOM = 10;
const DEFAULT_ZOOM_BRANCH = 13;

const DEFAULT_CENTER = {
  desktop: {
    lat: 52.557443,
    lng: 13.055179,
  },
  mobile: {
    lat: 52.557443,
    lng: 13.389179,
  },
};

const MAP_OPTIONS = {
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  styles: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#ff9900',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
  ],
};
